// eslint-disable-next-line import/no-extraneous-dependencies
import Model, { helper } from '@tripian/model';
import API_CONST from '../../../api/const/APICONST';
import data from '../../../data/data';
import {
  citiesConcat,
  poisConcat,
  questionsConcat,
  tripRefsConcatOne,
  tripsConcatOne,
  companionConcatOne,
  stepDeleted,
  planUpdated,
  reservationConcatOne,
  reactionConcatOne,
  favoriteConcatOne,
  stepUpdated,
  stepAdded,
  autoCompleteTagsConcat,
  topTensConcatOne,
} from './cacheHelper';

/**
 *
 * @param responseData
 * @param dataKey
 * @param lang
 * @param params important note: use params?. params can be undefined!
 */
const cache = <T>(responseData: T, dataKey: string, lang: Model.LangCodeKey, params?: any, pagination?: Model.Pagination) => {
  try {
    const cityId: number = params?.cityId || data.trip?.[lang]?.city?.id || 0;
    const tripHash: string = params?.tripHash || data.trip?.[lang]?.tripHash || '';

    if (dataKey === API_CONST.CONFIG.DATA_KEY) {
      const configList = responseData as Model.ConfigList;
      data.configList = configList;

      /**
       ******************************************************************************
       *
       * Cities
       *
       */
    } else if (dataKey === API_CONST.CITIES.DATA_KEY) {
      const modelCities = responseData as Model.City[];
      const cachedCities = citiesConcat(data.cities, lang, modelCities);
      data.cities = cachedCities;

      /**
       *
       * Citiy Search
       *
       */
      // } else if (dataKey === 'citiesSearch') {
      //   const modelCitiesSearch = (responseData as unknown) as Model.City[];
      //   data.cities = citiesConcat(data.cities, modelCitiesSearch);
      //   data.citiesSearch = modelCitiesSearch;
      /**
       *
       * City
       *
       */
      // } else if (dataKey === 'city') {
      //   const modelCity = (responseData as unknown) as Model.City;
      //   data.cities = citiesConcat(data.cities, [modelCity]);
      //   data.city = modelCity;

      /**
       ******************************************************************************
       *
       * Feedbacks
       *
       */
    } else if (dataKey === API_CONST.FEEDBACKS.DATA_KEY) {
      const feedbacks = responseData as Model.Feedbacks;
      if (!data.feedbacks) data.feedbacks = {} as Record<Model.LangCodeKey, Model.Feedbacks>;
      data.feedbacks[lang] = feedbacks;

      /**
       ******************************************************************************
       *
       * User Feedbacks
       *
       */
    } else if (dataKey === API_CONST.USER_FEEDBACKS.DATA_KEY) {
      const userFeedbacks = responseData as Model.UserFeedback[];
      if (!data.userFeedbacks) data.userFeedbacks = {} as Record<Model.LangCodeKey, Model.UserFeedback[]>;
      data.userFeedbacks[lang] = userFeedbacks;

      /**
       ******************************************************************************
       *
       * POIS Categories
       *
       */
    } else if (dataKey === API_CONST.POI_CATEGORIES.DATA_KEY) {
      const modelPoiCategories = responseData as Model.PoiCategory[];
      data.poiCategories[lang] = modelPoiCategories;

      /**
       ******************************************************************************
       *
       * AUTO COMPLETE TAGS
       *
       */
    } else if (dataKey === API_CONST.POIS_SEARCH_AUTO_COMPLETE_TAGS.DATA_KEY) {
      const modelTagArray = responseData as { id: number; name: string }[];
      data.autoCompleteTags = autoCompleteTagsConcat(data.autoCompleteTags, lang, modelTagArray);

      const qparams: { cityId: number; showOffersOnly: number; poiCategories?: string } | undefined = params
        ? (params as { cityId: number; showOffersOnly: number; poiCategories?: string })
        : undefined;

      const categories: string = qparams?.poiCategories || '';
      const categoryIds = categories.split(',').map(Number);

      const allTagsCheck = data.autoCompleteTagsAll[lang]?.find((pa) => pa.cityId === cityId && helper.arraysEqual(pa.poiCategories, categoryIds));
      if (allTagsCheck === undefined) {
        data.autoCompleteTagsAll[lang] = [
          ...(data.autoCompleteTagsAll[lang] || []),
          { cityId, poiCategories: categoryIds, tags: [...modelTagArray] },
        ];
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          'core.cache: This cityId-categoryGroup cached before. if forceRequest = true you can call this request repeatedly, else it is a cache logic problem!',
          cityId,
          categoryIds,
        );
      }

      /**
       ******************************************************************************
       *
       * POIS
       *
       */
    } else if (dataKey === API_CONST.POIS.DATA_KEY) {
      const modelPoiArray = responseData as Model.Poi[];
      const qparams: Model.PoisRequest | undefined = params ? (params as Model.PoisRequest) : undefined;
      const offersOnly = qparams?.withOffers !== undefined ? qparams.withOffers : 0;

      data.pois = poisConcat(data.pois, lang, modelPoiArray, offersOnly);

      if (
        qparams?.poiIds === undefined &&
        qparams?.bounds === undefined &&
        qparams?.mustTryIds === undefined &&
        qparams?.coordinate === undefined &&
        qparams?.distance === undefined &&
        qparams?.search === '' &&
        qparams?.page === 1
      ) {
        const categories: string = qparams?.poiCategories || '';
        const categoryIds = categories.split(',').map(Number);

        const allPoisCheck = data.poisAll[lang]?.find((pa) => pa.cityId === cityId && helper.arraysEqual(pa.poiCategories, categoryIds));

        if (allPoisCheck === undefined) {
          data.poisAll[lang] = [
            ...(data.poisAll[lang] || []),
            {
              cityId,
              poiCategories: categoryIds,
              showOffersOnly: offersOnly,
              pois: { data: modelPoiArray, pagination },
            },
          ];
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'core.cache: This cityId-categoryGroup page 1 cached before. if forceRequest = true you can call this request repeatedly, else it is a cache logic problem!',
            cityId,
            categoryIds,
          );
        }
      }
    } else if (dataKey === API_CONST.POIS_OPEN_SEARCH.DATA_KEY) {
      const modelPoiArray = responseData as Model.Poi[];

      const offersOnly: number = params?.showOffersOnly !== undefined ? params.showOffersOnly : 0;

      data.pois = poisConcat(data.pois, lang, modelPoiArray, offersOnly);

      if (
        params?.poiIds === undefined &&
        params?.bounds === undefined &&
        params?.mustTryIds === undefined &&
        params?.coordinate === undefined &&
        params?.distance === undefined &&
        params?.search === '' &&
        params?.page === 1
      ) {
        const categories: string = params?.poiCategories || '';
        const categoryIds = categories.split(',').map(Number);

        const allPoisCheck = data.poisAll[lang]?.find(
          (pa) => pa.cityId === cityId && helper.arraysEqual(pa.poiCategories, categoryIds) && pa.showOffersOnly === offersOnly,
        );

        if (allPoisCheck === undefined) {
          data.poisAll[lang] = [
            ...(data.poisAll[lang] || []),
            {
              cityId,
              poiCategories: categoryIds,
              showOffersOnly: offersOnly,
              pois: { data: modelPoiArray, pagination },
            },
          ];
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'core.cache: This cityId-categoryGroup page 1 cached before. if forceRequest = true you can call this request repeatedly, else it is a cache logic problem!',
            cityId,
            categoryIds,
          );
        }
      }

      /**
       *
       * POI
       *
       */
    } else if (dataKey === API_CONST.POI.DATA_KEY) {
      const modelPoi = responseData as Model.Poi;
      const offersOnly = params?.withOffers !== undefined ? params.withOffers : 0;
      data.pois = poisConcat(data.pois, lang, [modelPoi], offersOnly);

      /**
       ******************************************************************************
       *
       * USER
       *
       */
    } else if (dataKey === API_CONST.USER.DATA_KEY) {
      const modelUser = responseData as Model.User;
      data.user = modelUser;

      /**
       ******************************************************************************
       *
       * Questions
       *
       */
    } else if (
      dataKey === `${API_CONST.QUESTIONS.DATA_KEY}-trip` ||
      dataKey === `${API_CONST.QUESTIONS.DATA_KEY}-profile` ||
      dataKey === `${API_CONST.QUESTIONS.DATA_KEY}-companion`
    ) {
      const modelQuestions = responseData as Model.Question[];
      questionsConcat(data.questions, lang, modelQuestions);

      switch (dataKey) {
        case `${API_CONST.QUESTIONS.DATA_KEY}-trip`:
          if (!data.questionsTrip) data.questionsTrip = {} as Record<Model.LangCodeKey, Model.Question[]>;
          data.questionsTrip[lang] = modelQuestions;
          break;
        case `${API_CONST.QUESTIONS.DATA_KEY}-profile`:
          if (!data.questionsProfile) data.questionsProfile = {} as Record<Model.LangCodeKey, Model.Question[]>;
          data.questionsProfile[lang] = modelQuestions;
          break;
        case `${API_CONST.QUESTIONS.DATA_KEY}-companion`:
          if (!data.questionsCompanion) data.questionsCompanion = {} as Record<Model.LangCodeKey, Model.Question[]>;
          data.questionsCompanion[lang] = modelQuestions;
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`core.cache: Error. dataKey could not find in ${API_CONST.QUESTIONS.DATA_KEY}`, dataKey);
          break;
      }

      /**
       ******************************************************************************
       *
       * Favorites
       *
       */
    } else if (dataKey === API_CONST.FAVORITES.DATA_KEY) {
      const modelFavorites = responseData as Model.Favorite[];
      data.favorites[lang] = [...(data.favorites[lang] || []), { cityId, favoritePois: modelFavorites }];
    } else if (dataKey === API_CONST.FAVORITE_ADD.DATA_KEY) {
      const modelFavorite = responseData as Model.Favorite;
      data.favorites = favoriteConcatOne(data.favorites, lang, cityId, modelFavorite);
    } else if (dataKey === API_CONST.FAVORITE_DELETE.DATA_KEY) {
      const removedFavoriteId = (responseData as any).recordId;

      const cityFavoritesIndex = data.favorites[lang]?.findIndex((favorite) => favorite.cityId === cityId);
      if (cityFavoritesIndex > -1) {
        data.favorites[lang][cityFavoritesIndex].favoritePois = data.favorites[lang][cityFavoritesIndex].favoritePois.filter(
          (favorite) => favorite.id !== removedFavoriteId,
        );
      }

      /**
       ******************************************************************************
       *
       * TripRefs
       *
       */
    } else if (dataKey === API_CONST.TRIPS.DATA_KEY) {
      const modelTripRefs = responseData as Model.TripReference[];
      data.tripRefs = modelTripRefs;

      /**
       ******************************************************************************
       *
       * TripSavedRefs
       *
       */
      /* } else if (dataKey === API_CONST.TRIPS_SAVED.DATA_KEY) {
      const modelTripSavedRefs = (responseData as unknown) as Model.TripReference[];
      data.tripSavedRefs = modelTripSavedRefs; */

      /**
       ******************************************************************************
       *
       * Trip
       *
       */
    } else if (dataKey === API_CONST.TRIP.DATA_KEY || dataKey === API_CONST.TRIP_ADD.DATA_KEY || dataKey === API_CONST.TRIP_UPDATE.DATA_KEY) {
      const modelTrip = responseData as Model.Trip;

      //
      // TEMP CONTROL
      //
      // Control for null poi in step
      // we remove null pois from step
      // by @Irmak
      //
      /*       const modelTrip = { ...rawModelTrip };
      for (let i = 0; i < rawModelTrip.plans.length; i += 1) {
        const plan = rawModelTrip.plans[i];

        // TODO
        // TEMP
        const newSteps: Model.Step[] = [];
        plan.steps.forEach((step) => {
          if (step.poi) {
            newSteps.push(step);
          } else {
            // Log these pois for fix api data
            // eslint-disable-next-line no-console
            console.log('Poi null in step, step id:', step.id, 'trip hash:', rawModelTrip.trip_hash, 'trip id:', rawModelTrip.id);
          }
        });
        modelTrip.plans[i].steps = newSteps;
      } */
      //
      // TEMP CONTROL
      //

      const modelTripRef: Model.TripReference = {
        id: modelTrip.id,
        shared: modelTrip.shared,
        tripHash: modelTrip.tripHash,
        city: modelTrip.city,
        tripProfile: modelTrip.tripProfile,
      };
      if (data.tripRefs) data.tripRefs = tripRefsConcatOne(data.tripRefs, modelTripRef);

      data.trips = tripsConcatOne(data.trips, lang, modelTrip);
      data.trip[lang] = modelTrip;
    } else if (dataKey === API_CONST.TRIP_DELETE.DATA_KEY) {
      const removedTripId = (responseData as any).recordId;
      data.tripRefs = data.tripRefs?.filter((tripRef) => tripRef.id !== removedTripId);

      /**
       ******************************************************************************
       *
       * Plan
       *
       */
    } else if (dataKey === API_CONST.PLAN_UPDATE.DATA_KEY || dataKey === API_CONST.PLAN.DATA_KEY) {
      const rawModelPlan = responseData as Model.Plan;
      const tripUpdated = planUpdated(rawModelPlan, data.trip[lang]);
      if (tripUpdated) {
        tripsConcatOne(data.trips, lang, tripUpdated);
        data.trip[lang] = tripUpdated;
      }

      /**
       ******************************************************************************
       *
       * Step
       *
       */
    } else if (dataKey === API_CONST.STEP_ADD.DATA_KEY) {
      const addedStep = responseData as Model.Step;
      const planId: number | undefined = params?.planId;

      if (planId) {
        const tripUpdated = stepAdded(planId, addedStep, data.trip[lang]);
        if (tripUpdated) {
          data.trips = tripsConcatOne(data.trips, lang, tripUpdated);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'core.cache: Error. When step added cache, plan id params could not find in trip plans. Params - added step:',
          params,
          addedStep,
        );
      }
    } else if (dataKey === API_CONST.STEP_UPDATE.DATA_KEY) {
      const updatedStep = responseData as Model.Step;
      const tripUpdated = stepUpdated(updatedStep, data.trip[lang]);
      if (tripUpdated) {
        data.trips = tripsConcatOne(data.trips, lang, tripUpdated);
      }
    } else if (dataKey === API_CONST.STEP_DELETE.DATA_KEY) {
      const { recordId: deletedStepId } = responseData as { recordId: number };
      const tripUpdated = stepDeleted(deletedStepId, data.trip[lang]);
      if (tripUpdated) {
        data.trips = tripsConcatOne(data.trips, lang, tripUpdated);
      }

      /**
       ******************************************************************************
       *
       * Companions
       *
       */
    } else if (dataKey === API_CONST.COMPANIONS.DATA_KEY) {
      const modelCompanions = responseData as Model.Companion[];
      data.companions[lang] = modelCompanions;
      /**
       * Companions Add,  Companions Update
       */
    } else if (dataKey === API_CONST.COMPANION_ADD.DATA_KEY || dataKey === API_CONST.COMPANION_UPDATE.DATA_KEY) {
      const modelCompanion = responseData as Model.Companion;
      data.companions = companionConcatOne(data.companions, lang, modelCompanion);
      /**
       * Companions Delete
       */
    } else if (dataKey === API_CONST.COMPANION_DELETE.DATA_KEY) {
      const removedCompanionId = (responseData as any).recordId;
      data.companions[lang] = data.companions[lang]?.filter((companion) => companion.id !== removedCompanionId);

      /**
       ******************************************************************************
       *
       * Reservations
       *
       */
    } else if (dataKey === API_CONST.RESERVATIONS.DATA_KEY) {
      const modelReservations = responseData as Model.UserReservation[];
      data.reservations[lang] = modelReservations;

      /**
       * Reservations Add,Reservations Update
       */
    } else if (dataKey === API_CONST.RESERVATION_ADD.DATA_KEY || dataKey === API_CONST.RESERVATION_UPDATE.DATA_KEY) {
      const modelReservation = responseData as Model.UserReservation;
      data.reservations = reservationConcatOne(data.reservations, lang, modelReservation);

      /**
       * Reservations Delete
       */
    } else if (dataKey === API_CONST.RESERVATION_DELETE.DATA_KEY) {
      const removedReservationId = (responseData as any).recordId;
      data.reservations[lang] = data.reservations[lang]?.filter((reservation) => reservation.id !== removedReservationId);

      /**
       ******************************************************************************
       *
       * User Reactions
       *
       */
    } else if (dataKey === API_CONST.REACTIONS.DATA_KEY) {
      if (tripHash !== '') {
        const modelReactions = responseData as Model.UserReaction[];
        const userReactions = data.userReactions[lang]?.find((userReaction) => userReaction.tripHash === tripHash);
        if (!userReactions) {
          data.userReactions[lang] = [...(data.userReactions[lang] || []), { tripHash, userReactions: modelReactions }];
        }
      }
      /**
       * User Reactions Add, User Reactions Update
       */
    } else if (dataKey === API_CONST.REACTION_ADD.DATA_KEY || dataKey === API_CONST.REACTION_UPDATE.DATA_KEY) {
      if (tripHash !== '') {
        const modelReaction = responseData as Model.UserReaction;
        const hashIndex = data.userReactions[lang]?.findIndex((userReaction) => userReaction.tripHash === tripHash);
        if (hashIndex > -1) {
          data.userReactions = reactionConcatOne(data.userReactions, lang, tripHash, modelReaction);
        }
      }
      /**
       * User Reactions Delete
       */
    } else if (dataKey === API_CONST.REACTION_DELETE.DATA_KEY) {
      if (tripHash !== '') {
        const removedReactionId = (responseData as any).recordId;
        const hashIndex = data.userReactions[lang]?.findIndex((userReaction) => userReaction.tripHash === tripHash);
        if (hashIndex > -1) {
          data.userReactions[lang][hashIndex].userReactions = data.userReactions[lang][hashIndex].userReactions.filter(
            (reaction) => reaction.id !== removedReactionId,
          );
        }
      }
      /**
       * Top Ten
       */
    } else if (dataKey === API_CONST.TOP_TEN.DATA_KEY) {
      const topTen = responseData as Model.TopTen[];
      data.topTens = topTensConcatOne(data.topTens, lang, cityId, topTen);
    } else {
      if (!(data as any)[dataKey]) {
        (data as any)[dataKey] = {} as Record<Model.LangCodeKey, T>;
      }
      (data as any)[dataKey][lang] = responseData;
      if (!['token', 'register', 'city', 'trip-with-hash'].includes(dataKey)) {
        // eslint-disable-next-line no-console
        console.warn('core.cache: This dataKey did not cache.', dataKey);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('core.cache: Error', error);
  }
};

export default cache;
