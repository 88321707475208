// eslint-disable-next-line import/no-extraneous-dependencies
import Model, { helper } from '@tripian/model';

/**
 * Generic Helper
 */
interface GenericItem {
  id: number | string;
}

const cachedById = <T extends GenericItem>(array: T[], id: number | string) => array.find((arrayItem) => arrayItem.id === id);
const concatOneById = <T extends GenericItem>(array: T[], item: T, pushToEnd: boolean = true): T[] => {
  const arrayCopy = helper.deepCopy(array); // Mevcut listeyi kopyalar
  const itemIndex = arrayCopy.findIndex((arrayItem) => arrayItem.id === item.id); // Eklenmesi/güncellenmesi gereken POI'yi bulur
  if (itemIndex === -1) {
    if (pushToEnd) arrayCopy.push(item);
    // POI listede yoksa ekler
    else arrayCopy.splice(0, 0, item); // Alternatif olarak, liste başına ekler
  } else {
    arrayCopy[itemIndex] = item; // POI zaten varsa, günceller
  }
  return arrayCopy; // Güncellenmiş listeyi geri döndürür
};

/**
 *
 * Cities
 *
 */
export const cityCached = (cities: Model.City[], id: number): Model.City | undefined => cachedById<Model.City>(cities, id);
export const citiesConcat = (
  cities: Record<Model.LangCodeKey, Model.City[]>,
  lang: Model.LangCodeKey,
  newCities: Model.City[],
): Record<Model.LangCodeKey, Model.City[]> => {
  const citiesCopy = { ...cities };
  let langCities = [...(citiesCopy[lang] || [])];

  newCities.forEach((newCity) => {
    langCities = concatOneById<Model.City>(langCities, newCity);
  });

  citiesCopy[lang] = langCities;

  return citiesCopy;
};

/**
 *
 * POI
 *
 */
export const poiCached = (pois: Model.Poi[], id: number): Model.Poi | undefined => cachedById<Model.Poi>(pois, id);
export const poisConcat = (
  pois: Record<Model.LangCodeKey, { showOffersOnly: number; pois: Model.Poi[] }[]>,
  lang: Model.LangCodeKey,
  newPois: Model.Poi[],
  showOffersOnly: number,
): Record<Model.LangCodeKey, { showOffersOnly: number; pois: Model.Poi[] }[]> => {
  const poisCopy = { ...pois };
  const langPois = [...(poisCopy[lang] || [])];

  let poisGroup = langPois.find((group) => group.showOffersOnly === showOffersOnly);

  if (!poisGroup) {
    poisGroup = { showOffersOnly, pois: [] };
    langPois.push(poisGroup);
  }

  poisGroup.pois = newPois.reduce((acc, newPoi) => {
    const index = acc.findIndex((poi) => poi.id === newPoi.id);
    if (index !== -1) {
      acc[index] = newPoi;
    } else {
      acc.push(newPoi);
    }
    return acc;
  }, poisGroup.pois);

  poisCopy[lang] = langPois;
  return poisCopy;
};

export const autoCompleteTagsConcat = (
  tags: Record<Model.LangCodeKey, { id: number; name: string }[]>,
  lang: Model.LangCodeKey,
  newTags: { id: number; name: string }[],
): Record<Model.LangCodeKey, { id: number; name: string }[]> => {
  const tagsCopy = { ...tags };
  let langTags = [...(tagsCopy[lang] || [])];
  newTags.forEach((newTag) => {
    langTags = concatOneById<{ id: number; name: string }>(langTags, newTag);
  });
  tagsCopy[lang] = langTags;
  return tagsCopy;
};

/**
 ******************************************************************************
 *
 * Questions
 *
 */
export const questionCached = (questions: Model.Question[], id: number): Model.Question | undefined => cachedById<Model.Question>(questions, id);
export const questionsConcat = (
  questions: Record<Model.LangCodeKey, Model.Question[]>,
  lang: Model.LangCodeKey,
  newQuestions: Model.Question[],
): Record<Model.LangCodeKey, Model.Question[]> => {
  const questionsCopy = { ...questions };
  let langQuestions = [...(questionsCopy[lang] || [])];

  newQuestions.forEach((newQuestion) => {
    langQuestions = concatOneById<Model.Question>(langQuestions, newQuestion);
  });

  questionsCopy[lang] = langQuestions;

  return questionsCopy;
};

/**
 *
 * Trip Refs
 *
 */
export const tripRefCached = (tripRefs: Model.TripReference[], id: number): Model.TripReference | undefined =>
  cachedById<Model.TripReference>(tripRefs, id);
export const tripRefsConcatOne = (tripRefs: Model.TripReference[], newTripRef: Model.TripReference): Model.TripReference[] => {
  return concatOneById<Model.TripReference>(tripRefs, newTripRef, false);
};

/**
 *
 * Trips
 *
 */
export const tripCached = (trips: Model.Trip[], id: number): Model.Trip | undefined => cachedById<Model.Trip>(trips, id);
export const tripsConcatOne = (
  trips: Record<Model.LangCodeKey, Model.Trip[]>,
  lang: Model.LangCodeKey,
  newTrip: Model.Trip,
): Record<Model.LangCodeKey, Model.Trip[]> => {
  const tripsCopy = { ...trips };
  let langTrips = [...(tripsCopy[lang] || [])];

  langTrips = concatOneById<Model.Trip>(langTrips, newTrip);

  tripsCopy[lang] = langTrips;

  return tripsCopy;
};

/**
 *
 * Plan
 *
 */
export const planUpdated = (updatedPlan: Model.Plan, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy: Model.Trip = { ...trip, plans: [...trip.plans] };

  const updatedPlanIndex = tripCopy.plans.findIndex((plan) => plan.id === updatedPlan.id);
  if (updatedPlanIndex > -1) {
    tripCopy.plans[updatedPlanIndex] = updatedPlan;
    return tripCopy;
  }

  return undefined;
};

/**
 *
 * Step
 *
 */

export const stepAdded = (planId: number, addedStep: Model.Step, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy = helper.deepCopy(trip);

  const stepPlanIndex = trip.plans.findIndex((plan) => plan.id === planId);
  const newSteps: Model.Step[] = helper.deepCopy(trip.plans[stepPlanIndex].steps);
  newSteps.push(addedStep);
  tripCopy.plans[stepPlanIndex].steps = newSteps;

  return tripCopy;
};
export const stepUpdated = (updatedStep: Model.Step, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy = helper.deepCopy(trip);
  let stepFound: boolean = false;
  for (let i = 0; i < trip.plans.length; i += 1) {
    const updatedPlanIndex = trip.plans[i].steps.findIndex((step) => step.id === updatedStep.id);
    if (updatedPlanIndex > -1) {
      stepFound = true;
      const newSteps: Model.Step[] = helper.deepCopy(trip.plans[i].steps);
      newSteps[updatedStep.order] = updatedStep;
      tripCopy.plans[i].steps = newSteps;
      break;
    }
  }

  // eslint-disable-next-line no-console
  if (stepFound === false) console.error('cacheHelper.stepUpdated error. stepId could not find in trip plans', tripCopy, updatedStep);

  return tripCopy;
};
export const stepDeleted = (deletedStepId: number, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy = helper.deepCopy(trip);
  let stepFound: boolean = false;
  for (let i = 0; i < trip.plans.length; i += 1) {
    const deletedStepIndex = trip.plans[i].steps.findIndex((step) => step.id === deletedStepId);
    if (deletedStepIndex > -1) {
      stepFound = true;
      const newSteps: Model.Step[] = [];
      const filteredSteps = helper.deepCopy(trip.plans[i].steps.filter((step) => step.id !== deletedStepId));
      for (let j = 0; j < filteredSteps.length; j += 1) {
        newSteps.push(filteredSteps[j]);
        newSteps[j].order = j;
      }
      tripCopy.plans[i].steps = newSteps;
      break;
    }
  }

  // eslint-disable-next-line no-console
  if (stepFound === false) console.error('cacheHelper.stepDeleted error. Deleted step id could not find in trip plans', tripCopy, deletedStepId);

  return tripCopy;
};

/**
 *
 * Favorites
 *
 */
export const favoriteCached = (favorites: Model.Favorite[], id: number): Model.Favorite | undefined => cachedById<Model.Favorite>(favorites, id);
export const favoriteConcatOne = (
  favorites: Record<Model.LangCodeKey, { cityId: number; favoritePois: Model.Favorite[] }[]>,
  lang: Model.LangCodeKey,
  cityId: number,
  newFavorite: Model.Favorite,
): Record<Model.LangCodeKey, { cityId: number; favoritePois: Model.Favorite[] }[]> => {
  const favoritesCopy = { ...favorites };
  const cityFavorites = favoritesCopy[lang]?.find((favorite) => favorite.cityId === cityId);

  if (cityFavorites) {
    cityFavorites.favoritePois = concatOneById<Model.Favorite>(cityFavorites.favoritePois, newFavorite);
  } else {
    if (!favoritesCopy[lang]) {
      favoritesCopy[lang] = [];
    }
    favoritesCopy[lang].push({ cityId, favoritePois: [newFavorite] });
  }

  return favoritesCopy;
};

/**
 *
 * Companions
 *
 */
export const companionCached = (companions: Model.Companion[], id: number): Model.Companion | undefined =>
  cachedById<Model.Companion>(companions, id);
export const companionConcatOne = (
  companions: Record<Model.LangCodeKey, Model.Companion[]>,
  lang: Model.LangCodeKey,
  newCompanion: Model.Companion,
): Record<Model.LangCodeKey, Model.Companion[]> => {
  const companionsCopy = { ...companions };
  let langCompanions = [...(companionsCopy[lang] || [])];

  langCompanions = concatOneById<Model.Companion>(langCompanions, newCompanion, false);

  companionsCopy[lang] = langCompanions;

  return companionsCopy;
};

/**
 *
 * Reservations
 *
 */
export const reservationCached = (reservations: Model.UserReservation[], id: number): Model.UserReservation | undefined =>
  cachedById<Model.UserReservation>(reservations, id);
export const reservationConcatOne = (
  reservations: Record<Model.LangCodeKey, Model.UserReservation[]>,
  lang: Model.LangCodeKey,
  newReservation: Model.UserReservation,
): Record<Model.LangCodeKey, Model.UserReservation[]> => {
  const reservationsCopy = { ...reservations };

  let langReservations = [...(reservationsCopy[lang] || [])];

  langReservations = concatOneById<Model.UserReservation>(langReservations, newReservation, true);

  reservationsCopy[lang] = langReservations;

  return reservationsCopy;
};

/**
 *
 * Reactions
 *
 */
export const reactionCached = (reactions: Model.UserReaction[], id: number): Model.UserReaction | undefined =>
  cachedById<Model.UserReaction>(reactions, id);
export const reactionConcatOne = (
  reactions: Record<Model.LangCodeKey, { tripHash: string; userReactions: Model.UserReaction[] }[]>,
  lang: Model.LangCodeKey,
  tripHash: string,
  newReaction: Model.UserReaction,
): Record<Model.LangCodeKey, { tripHash: string; userReactions: Model.UserReaction[] }[]> => {
  const reactionsCopy = { ...reactions };
  const tripReactions = reactionsCopy[lang]?.find((reaction) => reaction.tripHash === tripHash);

  if (tripReactions) {
    tripReactions.userReactions = concatOneById<Model.UserReaction>(tripReactions.userReactions, newReaction);
  } else {
    if (!reactionsCopy[lang]) {
      reactionsCopy[lang] = [];
    }
    reactionsCopy[lang].push({ tripHash, userReactions: [newReaction] });
  }

  return reactionsCopy;
};

export const topTensConcatOne = (
  topTens: Record<Model.LangCodeKey, { cityId: number; topTen: Model.TopTen[] }[]>,
  lang: Model.LangCodeKey,
  cityId: number,
  newTopTen: Model.TopTen[],
): Record<Model.LangCodeKey, { cityId: number; topTen: Model.TopTen[] }[]> => {
  const topTensCopy = { ...topTens };
  const cityTopTen = topTensCopy[lang]?.find((topTen) => topTen.cityId === cityId);

  if (cityTopTen) {
    cityTopTen.topTen = newTopTen;
  } else {
    if (!topTensCopy[lang]) {
      topTensCopy[lang] = [];
    }
    topTensCopy[lang].push({ cityId, topTen: newTopTen });
  }

  return topTensCopy;
};

export default {
  citiesConcat,
  poisConcat,
  questionsConcat,
  tripRefsConcatOne,
  tripsConcatOne,
  companionConcatOne,
  stepDeleted,
  planUpdated,
  reservationConcatOne,
  reactionConcatOne,
  favoriteConcatOne,
  stepUpdated,
  stepAdded,
  autoCompleteTagsConcat,
  topTensConcatOne,
};
